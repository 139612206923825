import React from 'react'

function Page404() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <h1 style={{ color: "white" }}>Page Not Found</h1>
    </div>
  )
}

export default Page404